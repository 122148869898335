<!--
 * Description: 视频播放层
-->

<template>
    <div v-if="videoPlay" class="video-container">
        <p class="video-title">
            <span>{{videoInfo.title}}</span>
            <i class="el-icon-close" @click="closePlay"></i>
        </p>
        <video :src=videoInfo.playUrl controls preload autoplay></video>
    </div>
</template>
<script>
export default {
    name : "videoPlay",
    data(){
        return {
            videoPlay : false,
            videoInfo : {
                title : ""
            }
        }
    },
    methods: {
        show(id){
            this.videoPlay = true;
            if( typeof id == "string" ){
                this.getVideo(id)
            }
        },
        getVideo(id){
            this.$api.guide.detail({id}).then(res=>{
                this.$set(this,"videoInfo",res)
            })
        },
        closePlay(){
            this.videoPlay = false;
            this.videoInfo = null;
        }
    }
}
</script>

<style lang="scss" scoped>
    .video-container {
        z-index: 10000;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.6);
        padding: 70px 250px;
        .video-title {
            z-index: 1000;
            position: absolute;
            top: 70px;
            height: 60px;
            width: calc(100% - 500px);
            background: rgba($color: #252528, $alpha: 0.6);
            padding: 0 30px;
            color: #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
            font-size: 19px;
            cursor: pointer;
            }
        }
        video {
            width: 100%;
            height: 100%;
            background: #000000;
        }
    }
</style>