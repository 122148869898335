<!--
 * Description: 新手指引-视频列表
-->

<template>
    <div class="guide-container">
        <div class="page-header">
            <!-- <i class="el-icon-arrow-left" @click="back"></i> -->
            <span>新手指引</span>
        </div>
        <div class="page-main">
            <t-pagination
               @currentChange="currentChange"
               @sizeChange="sizeChange"
               :pages="pages">
                <template v-slot:table>
                    <div class="video-list">
                        <div v-for="(item,index) in videoList">
                            <div>
                                <img :src="item.cover" alt="">
                                <img 
                                    class="play-button" 
                                    src="@/assets/image/guide/video_fengmian_btn_play@2x.png" alt=""
                                    @click="playVideo(item)">
                            </div>
                            <p class="video-name" :title="item.title">
                                {{index+1}}.
                                {{item.title}}
                            </p>
                        </div>
                    </div>
                </template>
            </t-pagination>
        </div>
        <video-play ref="video"></video-play>
    </div>
</template>

<script>
import pagination from '@/mixins/pagination'
import VideoPlay from "@/components/video/index.vue";
export default {
    name : "guide",
    mixins: [pagination],
    components : {VideoPlay},
    data(){
        return {
            videoPlay : false,
            videoInfo : null,
            videoList : [],
            pages : {
                currentPage: 1,
                pageCount: 1,
                perPage: 12,
                totalCount: 200
            }
        }
    },
    mounted(){
        this.page();
    },  
    methods: {
        back(){
            this.$router.push({
                name : "home"
            })
        },
        page(){
            this.$api.guide.getList({
                page: 1,
                perPage: 12
            }).then(res => {
                this.videoList = res.items
                this.pages = res.pages;
            })
        },
        //播放视频
        playVideo(item){
            this.$refs.video.show(String(item.id))
        },
    }
}
</script>

<style lang="scss" scoped>
    .guide-container {
		height: 100%;
		background: #fff;
		padding: 24px;
		border-radius:16px;
        .page-header {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #383D41;
            line-height: 28px;
			margin-bottom: 16px;
        }
        .page-main {
			height: calc(100% - 44px);
			overflow-y: scroll;
			&::-webkit-scrollbar{width:0;}
            .video-list {
                display: flex;
                flex-wrap: wrap;
                > div {
                    width: calc(25% - 18px);
					margin-bottom: 16px;
					margin-right: 24px;
					&:nth-child(4n){
						margin-right: 0;
					}
                    > div {
                        height: 164px;
                        line-height: 164px;
                        text-align: center;
                        width: 100%;
                        border-radius: 8px;
                        background: #F4F7FA;
                        position: relative;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
							border-color: #F4F7FA;
							border-radius: 8px;
                        }
                        .play-button {
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            left: calc(50% - 20px);
                            top: calc(25% + 10px);
                            cursor: pointer;
                        }
                    }
                    .video-name {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #383D41;
                        line-height: 22px;
						margin: 8px 0;
                    }
                }
            }
        }
        .video-container {
            z-index: 10000;
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            background: rgba($color: #000000, $alpha: 0.6);
            padding: 70px 250px;
            .video-title {
                z-index: 1000;
                position: absolute;
                top: 70px;
                height: 60px;
                width: calc(100% - 500px);
                background: rgba($color: #252528, $alpha: 0.6);
                padding: 0 30px;
                color: #FFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                i {
                    font-size: 19px;
                    cursor: pointer;
                }
            }
            video {
                width: 100%;
                height: 100%;
                background: #000000;
            }
        }
    }
</style>